<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
    class="pl-0 pt-0"
  >
    <!-- <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-message-alert-outline
      </v-icon> Alerts Histories
    </h2>
    <br> -->
    <v-card
      color="#fff"
      class="project-list-card mt-2"
    >
      <v-col
        class="text-right table-top-border"
      >
        <v-btn
          color="#000"
          @click="sendEmergencyAlert()"
        >
          Send Alert
        </v-btn>
      </v-col>
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
              :hint="isSearchAdded ? searchHint : ''"
              @keyup.enter="handleSearch"
              @keydown.esc="handleSearchClear"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="alertHistories"
        :search="search"
        class="elevation-1 table-checkin"
        mobile-breakpoint="100"
        :server-items-length="totalRecords"
        :footer-props="{
          'items-per-page-options': [10, 20, 30],
        }"
        :page.sync="page"
        @pagination="handlePagination"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td
              class="more-link"
              @click="showResponse(item.id)"
            >
              {{ item.title }}
            </td>
            <td>{{ item.description }}</td>
            <td>{{ item.created_at | convertToLocal }}</td>
            <td>
              <font class="success-style">
                Send*
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="showResponse(item.id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#C62828"
                @click.stop
                @click="deleteAlertMessage()"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <send-emergency-msg-landing
      v-if="showAlertSendForm"
      @get-alert-histories="getLatestAlertHistories"
      @closed="showAlertSendForm = false"
    />
  </v-container>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import SendEmergencyMsgLanding from './SendEmergencyMsgLanding.vue';
  import Constants from 'src/constants';
  import moment from 'moment';
  import global from 'src/mixins/global';

  export default {
    name: 'EmergencyMessages',
    components: {
      'centre-spinner': spinner,
      'send-emergency-msg-landing': SendEmergencyMsgLanding,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
    },
    mixins: [global],
    data () {
      return {
        search: null,
        showAlertSendForm: false,
        loading: false,
        headers: [
          { text: 'Alert Title', align: 'start', value: 'title' },
          { text: 'Description', value: 'description' },
          { text: 'Send At', value: 'created_at' },
          { text: 'Status', value: 'status' },
          { text: 'Responses', value: 'more' },
          { text: 'Delete', value: 'delete' },
        ],
        page: 1,
      };
    },
    computed: {
      isSearchAdded () {
          if (this.search === null || this.search === '') {
            return false;
          }
          return true;
        },
      alertHistories () {
        const list = this.$store.getters['widgets/getAlertHistories'];
        // const sortedList = list.data.sort((a, b) => {
        //   return new Date(b.created_at) - new Date(a.created_at);
        // });
        return list.data;
      },
      paginatedAttendanceStructure () {
            return this.$store.getters['widgets/getAlertHistories'];
        },
      totalRecords () {
          if (Object.keys(this.paginatedAttendanceStructure).length > 0) {
            return this.paginatedAttendanceStructure.total;
          }
          return 0;
        },
        searchHint () {
          return `Press Enter to filter by name: '${this.search}' or press ESC to clear.`;
        },
    },
    async mounted () {
      await this.getAlertHistories({ itemsPerPage: 10, pageStart: 0 });
    },
    methods: {
      handleSearchClear () {
        this.search = null;
        this.getAlertHistories({ itemsPerPage: 10, pageStart: 0 });
      },
      handleSearch () {
        this.pageStart = 1;
        this.page = 1;
        this.getAlertHistories({ itemsPerPage: 10, pageStart: this.pageStart });
      },
      handlePagination (e) {
        this.getAlertHistories({ itemsPerPage: 10, pageStart: e.pageStart });
      },
      async getAlertHistories ({ itemsPerPage, pageStart }) {
        this.loading = true;
        await this.$store.dispatch('widgets/fetchAlertHistories', {
          params: {
            title: this.search === '' ? null : this.search,
            limit: itemsPerPage,
            startPage: pageStart,
          },
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      sendEmergencyAlert () {
        this.showAlertSendForm = true;
      },
      getLatestAlertHistories () {
        setTimeout(() => {
          this.$store.dispatch('widgets/fetchAlertHistories');
        }, 3000);
      },
      deleteAlertMessage () {
        this.$store.dispatch('alert/onAlert', {
          message: 'You are not authorized to perform this action.',
          type: Constants.ALERT_TYPE_INFO,
        });
      },
      showResponse (alertId) {
        this.$router.push(`widgets/alerts/${alertId}/responses`);
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.success-style {
  color: #2E7D32;
  font-weight: bold;
  font-size: 12px;
}
.more-link{
  cursor: pointer;
}
.alert-style {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
.table-checkin {
  border-radius: 0px !important;
  background: #fff !important;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.table-checkin tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-checkin tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.action-btn {
  background: #fff !important;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
  /* margin-top: -7px; */
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
.info-text{
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.sub-title-style{
  color: #aaa;
}
.project-list-card {
  box-shadow: none !important;
}
</style>
