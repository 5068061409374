<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
    class="pl-0 pt-0"
  >
    <!-- <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-account-question-outline
      </v-icon> Suspicious Check-Ins
    </h2>
    <br> -->
    <div v-if="subscriptionCheck('active')">
      <v-card
        color="#fff"
        class="project-list-card mt-0"
      >
        <br>
        <v-col class="text-right table-top-border">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                fab
                dark
                outlined
                color="#37474F"
                small
                class="btn-style"
                v-on="on"
                @click="clearRecordsFilteringParams"
              >
                <v-icon
                  small
                  dark
                >
                  mdi-file-multiple-outline
                </v-icon>
              </v-btn>
            </template>
            <span>ALL Records</span>
          </v-tooltip>
          <v-btn
            color="#000"
            @click="showFilterForm = true"
          >
            Filter
          </v-btn>
        </v-col>
        <v-card-title class="filter-card">
          <v-row>
            <v-col
              cols="12"
              lg="4"
              md="5"
              sm="12"
            >
              <v-text-field
                v-model="search"
                label="Search"
                append-icon="mdi-magnify"
                outlined
                :page.sync="page"
                dense
                :hint="isSearchAdded ? searchHint : ''"
                @keyup.enter="getAllSuspiciousCheckIns({ itemsPerPage: 10, pageStart: 0 })"
                @keydown.esc="handleClearSearch"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="suspiciousCheckIns"
          :search="search"
          class="elevation-1 table-checkin"
          mobile-breakpoint="100"
          :footer-props="{
          'items-per-page-options': [10, 20, 30],
          }"
          :server-items-length="totalRecords"
          @pagination="getAllSuspiciousCheckIns"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.app_user.first_name }} {{ item.app_user.last_name }}</td>
              <td>{{ item.app_user.company.name }}</td>
              <td>
                <font v-if="item.project_gate !== null && item.project_gate.project !== null">
                  {{ item.project_gate.project.name }}
                </font>
                <font v-else>
                  N/A
                </font>
              </td>
              <td>
                <font v-if="item.project_gate !== null">
                  {{ item.project_gate.name }}
                </font>
                <font v-else>
                  N/A
                </font>
              </td>
              <td>
                <v-chip
                  outlined
                  class="type"
                  color="#37474F"
                  dark
                >
                  {{ item.checkin_type }}
                </v-chip>
              </td>
              <td>{{ item.created_at | convertToLocal }}</td>
              <td>{{ item.reason }}</td>
            </tr>
          </template>
        </v-data-table>
        <div class="py-3" />
      </v-card>
      <centre-spinner :loading="loading" />
      <filter-suspicious-check-ins
        v-if="showFilterForm"
        @closed="showFilterForm = false"
        @dataToFilter="addFilter"
      />
    </div>
    <div v-else>
      <v-alert
        text
        prominent
        type="warning"
        color="#006883"
        border="left"
        class="alert-style"
      >
        Upgrade to Premium Version to view the CheckIns.
      </v-alert>
    </div>
  </v-container>
</template>
<script>
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import moment from 'moment';
import FilterSuspiciousCheckIns from './FilterSuspiciousCheckIns.vue';
import global from 'src/mixins/global';

export default {
  name: 'SuspiciousCheckIns',
  components: {
    'centre-spinner': spinner,
    'filter-suspicious-check-ins': FilterSuspiciousCheckIns,
  },
  filters: {
    convertToLocal (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
    },
  },
  mixins: [global],
  data () {
    return {
      search: null,
      loading: false,
      headers: [
        { text: 'Name', align: 'start', value: 'app_user.first_name' },
        { text: 'Company Name', value: 'name' },
        { text: 'Project', value: 'project' },
        { text: 'Gate', value: 'Gate' },
        { text: 'Type', value: 'type' },
        { text: 'Reported At', value: 'created_at' },
        { text: 'Alert', value: 'reason' },
      ],
      showFilterForm: false,
      page: 1,
      skipPaginatonCall: false,
      from: null,
      to: null,
      projectId: null,
    };
  },
  computed: {
    suspiciousCheckIns () {
      const list = this.$store.getters['widgets/getSuspiciousCheckIns'];
      // const sortedList = list.data.sort((a, b) => {
      //   return new Date(b.created_at) - new Date(a.created_at);
      // });
      return list.data;
    },
    paginatedAttendanceStructure () {
      return this.$store.getters['widgets/getSuspiciousCheckIns'];
    },
    totalRecords () {
      if (Object.keys(this.paginatedAttendanceStructure).length > 0) {
        return this.paginatedAttendanceStructure.total;
      }
      return 0;
    },
    isSearchAdded () {
      if (this.search === null || this.search === '') {
        return false;
      }
      return true;
    },
    searchHint () {
      return `Press Enter to filter by name: '${this.search}' or press ESC to clear.`;
    },
  },
  async mounted () {
    // await this.subscriptionCheck('active') && await this.getAllSuspiciousCheckIns({ itemsPerPage: 10, pageStart: 0 });
  },
  methods: {
    handlePagination (e) {
      this.getAllSuspiciousCheckIns({ itemsPerPage: 10, pageStart: 0 });
    },
    handleClearSearch () {
      this.search = null;
      this.getAllSuspiciousCheckIns({ itemsPerPage: 10, pageStart: 0 });
    },
    async clearRecordsFilteringParams () {
      this.from = null;
      this.to = null;
      this.projectId = null;
      this.search = null;
      await this.getAllSuspiciousCheckIns({ itemsPerPage: 10, pageStart: 0 });
    },
    handleSearch () {
      this.pageStart = 0;
      this.page = 1;
      this.getAllSuspiciousCheckIns({ itemsPerPage: 10, pageStart: this.pageStart });
    },
    async addFilter (data) {
this.from = data.from;
this.to = data.to;
this.projectId = data.projectId;
this.pageStart = 0;
this.page = 1;
await this.getAllSuspiciousCheckIns({ itemsPerPage: 10, pageStart: this.pageStart });
    },
    async getAllSuspiciousCheckIns ({ itemsPerPage, pageStart }) {
      this.loading = true;
       await this.$store.dispatch('widgets/fetchSuspiciousCheckIns', {
        params: {
          limit: itemsPerPage,
          startPage: pageStart,
          name: this.search === '' ? null : this.search,
          from: this.from,
          to: this.to,
          projectId: this.projectId,
        },
      }).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}

.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}

.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}

.v-data-table {
  background-color: #ECEFF1;
}

.title-style {
  color: #37474F;
}

.icon-style {
  color: #37474F;
  font-size: 45px;
  margin-left: 20px;
}

.type {
  text-transform: capitalize;
}

.btn-style {
  margin-right: 15px;
}

.alert-style {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}

.table-checkin {
  border-radius: 0px !important;
  background: #fff !important;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}

.table-checkin tbody tr:nth-of-type(even) {
  background: #fff !important;
}

.table-checkin tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}

.action-btn {
  background: #fff !important;
}

.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
  /* margin-top: -7px; */
}

.table-top-border {
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}

.info-text {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.sub-title-style {
  color: #aaa;
}

.project-list-card {
  box-shadow: none !important;
}
</style>
