<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
    class="pl-0 pt-0"
  >
    <!-- <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-account-arrow-right-outline
      </v-icon> Manual Check-Outs
    </h2>
    <br> -->
    <div v-if="subscriptionCheck('active')">
      <v-card
        color="#fff"
        class="project-list-card mt-2"
      >
        <v-col
          class="text-right table-top-border"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                fab
                dark
                outlined
                color="#37474F"
                small
                class="btn-style"
                v-on="on"
                @click="ClearFilterManualCheckOuts()"
              >
                <v-icon
                  small
                  dark
                >
                  mdi-file-multiple-outline
                </v-icon>
              </v-btn>
            </template>
            <span>ALL Records</span>
          </v-tooltip>
          <v-btn
            color="#000"
            @click="showFilterForm = true"
          >
            Filter
          </v-btn>
        </v-col>
        <v-card-title class="filter-card">
          <v-row>
            <v-col
              cols="12"
              lg="4"
              md="5"
              sm="12"
            >
              <v-text-field
                v-model="search"
                label="Search"
                append-icon="mdi-magnify"
                outlined
                dense
                @keyup.enter="handleSearch"
                @keydown.esc="handleClearSearch"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          v-model="search"
          :headers="manualCheckOut.headers"
          :items="manualCheckOuts"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="id"
          disable-sort
          class="elevation-1 table-checkin"
          :footer-props="{
          'items-per-page-options': [10, 20, 30]
          }"
          mobile-breakpoint="100"
          :server-items-length="totalRecords"
          :page.sync="page"
          @pagination="handlePagination"
        >
          <template v-slot:item="{ item, expand, isExpanded }">
            <tr>
              <td>{{ item.checkin_record.app_user.first_name }} {{ item.checkin_record.app_user.last_name }}</td>
              <td>{{ item.checkin_record.app_user.company.name }}</td>
              <td>
                {{ item.checkin_record.project.name }}
              </td>
              <td>
                {{ item.checkin_record.checkout_time | convertToLocal }}
              </td>
              <td>{{ item.created_at | convertToLocal }}</td>
              <td>
                <v-btn
                  icon
                  small
                  @click.stop
                  @click="expand(!isExpanded)"
                >
                  <v-icon
                    v-if="!isExpanded"
                  >
                    mdi-arrow-down-drop-circle
                  </v-icon>
                  <v-icon v-if="isExpanded">
                    mdi-arrow-up-drop-circle
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-card>
                <v-toolbar
                  color="#37474F"
                  dark
                >
                  <v-toolbar-title class="my-tool-bar-title-style">
                    Manual Check-Out Reason
                  </v-toolbar-title>
                </v-toolbar>
                <v-list>
                  <v-list-item-content class="ml-3">
                    <v-list-item-title>
                      <span class="manual-reason">{{ item.reason }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list>
              </v-card>
            </td>
          </template>
        </v-data-table>
        <div class="py-3" />
      </v-card>
      <centre-spinner
        :loading="loading"
      />
      <filter-manual-check-outs
        v-if="showFilterForm"
        @closed="showFilterForm = false"
        @dataToFilter="filteredData"
      />
    </div>
    <div v-else>
      <v-alert
        text
        prominent
        type="warning"
        color="#006883"
        border="left"
        class="alert-style"
      >
        Upgrade to Premium Version to view the CheckIns.
      </v-alert>
    </div>
  </v-container>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';
  import FilterManualCheckOuts from './FilterManualCheckOuts.vue';
  import global from 'src/mixins/global';

  export default {
    name: 'ManualCheckOuts',
    components: {
      'centre-spinner': spinner,
      'filter-manual-check-outs': FilterManualCheckOuts,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
    },
    mixins: [global],
    data () {
      return {
        search: null,
        loading: false,
        manualCheckOut: {
          headers: [
            { text: 'Name', align: 'start', value: 'checkin_record.app_user.first_name' },
            { text: 'Company Name', value: 'name' },
            { text: 'Project', value: 'project' },
            { text: 'Recorded Check-Out', value: 'checkout_time' },
            { text: 'Actual Check-Out', value: 'created_at' },
            { text: 'More', value: 'more' },
          ],
        },
        showFilterForm: false,
        expanded: [],
        singleExpand: true,
        from: null,
        to: null,
        projectId: null,
        page: 1,
        filterIsOn: false,
      };
    },
    computed: {
      paginatedRecordsStructure () {
        return this.$store.getters['widgets/getManualCheckOuts'];
      },
      checkInRecords () {
        const records = this.paginatedRecordsStructure;
        return records.data;
      },
      totalRecords () {
        if (Object.keys(this.paginatedRecordsStructure).length > 0) {
          return this.paginatedRecordsStructure.total;
        }
        return 0;
      },
      manualCheckOuts () {
        const list = this.$store.getters['widgets/getManualCheckOuts'];
        // const sortedList = list.sort((a, b) => {
        //   return new Date(b.created_at) - new Date(a.created_at);
        // });
        return list.data;
      },
      isSearchAdded () {
        if (this.search === null || this.search === '') {
          return false;
        }
        return true;
      },
    },
    watch: {
      isSearchAdded (val) {
        if (val === false) {
          this.getManualCheckOuts({ itemsPerPage: 10, pageStart: 0 });
        }
      },
    },

    methods: {
      handleSearch () {
        this.page = 1;
        this.pageStart = 0;
        this.getManualCheckOuts({ itemsPerPage: 10, pageStart: this.pageStart });
      },
      handleClearSearch () {
        this.page = 1;
        this.search = null;
        this.pageStart = 0;
        this.getManualCheckOuts({ itemsPerPage: 10, pageStart: this.pageStart });
      },
      handlePagination (e) {
          this.getManualCheckOuts({ itemsPerPage: 10, pageStart: e.pageStart });
      },
      async getManualCheckOuts ({ itemsPerPage, pageStart }) {
        this.loading = true;
        await this.$store.dispatch('widgets/fetchManualCheckOuts', {
          params: {
            limit: itemsPerPage,
            startPage: pageStart,
            name: this.search === '' ? null : this.search,
            from: this.from,
            to: this.to,
            projectId: this.projectId,
          },
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      async filteredData (data) {
        this.from = data.from;
        this.to = data.to;
        this.projectId = data.projectId;
        await this.getManualCheckOuts({ itemsPerPage: 10, pageStart: 0 });
      },
     async ClearFilterManualCheckOuts () {
        this.from = null;
        this.to = null;
        this.projectId = null;
        this.search = null;
        await this.getManualCheckOuts({ itemsPerPage: 10, pageStart: 0 });
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
  margin-left: 20px;
}
.type {
  text-transform: capitalize;
}
.btn-style {
  margin-right: 15px;
}
.manual-reason {
  font-weight: bold;
  color: #37474F;
}
.alert-style {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
.table-checkin {
  border-radius: 0px !important;
  background: #fff !important;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.table-checkin tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-checkin tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.action-btn {
  background: #fff !important;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
  /* margin-top: -7px; */
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
.info-text{
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.sub-title-style{
  color: #aaa;
}
.project-list-card {
  box-shadow: none !important;
}
</style>
